module.exports = new Promise((resolve) => {
          const tenant = window.location.host.split('.chronosphere.io').shift();
          const isDev = window.location.host.split(':').shift() === 'localhost';
          const useRC = isDev || tenant === 'rc-uat-ui' || tenant === 'rc-uat-ui2';
          const remoteUrl = useRC
            ? 'https://rc-docs.chronosphere.io/remote.js'
            : 'https://docs.chronosphere.io/remote.js';
          const script = document.createElement('script');
          script.async = true;
          script.defer = true;
          script.src = remoteUrl;

          script.onload = () => {
            // the injected script has loaded and is available on window
            // we can now resolve this Promise
            const proxy = {
              get: (request) => window.docs.get(request),
              init: (arg) => {
                try {
                  return window.docs.init(arg);
                } catch (e) {
                  console.log('remote container already initialized');
                }
              },
            };
            resolve(proxy);
          };

          script.onerror = () => {
            resolve('failed to load script');
          };
          document.body.appendChild(script);
        });
        ;